import MyUploadAdapter from "./MyUploadAdapter";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function SimpleUploadAdapterPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return new MyUploadAdapter(loader, editor.config._config.simpleUpload);
  };
}

class CustomCkeditor {
  constructor(target = "#editor", onCreate = () => {}) {
    this.target = target;

    this.onCreate = onCreate;
  }

  create(editorConfig){
    return ClassicEditor.create( document.querySelector( '#editor' ), {
      licenseKey: '',
      extraPlugins: [SimpleUploadAdapterPlugin],
      simpleUpload: editorConfig.simpleUpload,
      toolbar: editorConfig.toolbar,
    } ).then(editor => {

      window.editor = editor;

      // 세팅 완료 후 할일이 있다면
      this.onCreate(editor);

    }).catch(error => {
      console.error(error);
    });
  }
}

export default CustomCkeditor;