import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {encodeBase64} from "@/utils/ImageUtil";

class MyUploadAdapter {
  constructor(loader, simpleUpload) {
    this.loader = loader;
    this.simpleUpload = simpleUpload;
  }

  async upload() {
    return this.loader.file
      // eslint-disable-next-line no-async-promise-executor
      .then(file => new Promise(async (resolve) => {
        const rtnData = await this._sendRequest(file);

        resolve({
          default: rtnData.url,
        });
      }));
  }

  async _sendRequest(file) {
    const path = await encodeBase64(file);

    const fileUploadData = {
      imageFile: {
        path: path,
        name: file.name,
      },
    };

    return await GolfErpAPI.saveImage(this.simpleUpload.headers.boardId, this.simpleUpload.headers.postId, fileUploadData);
  }
}

export default MyUploadAdapter;