<template>
  <div id="popupWrapper">
    <ejs-dialog
      ref="postRegistrationPopup"
      header="게시물 등록"
      width="1000"
      :isModal="true"
      :allowDragging="true"
      :showCloseIcon="true"
      :enableResize="false"
      :cssClass="
        newSaveCheck
          ? $t('className.popup.productCodePopupAdd')
          : $t('className.popup.productCodePopupModify')
      "
      @close="onPopupClosed"
    >
      <div
        class="window postRegistrationPopup"
        :class="boardInfo.boardId"
      >
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <section class="article-section section-0101">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">게시물 정보</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <div class="title required">구분</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="div"
                                        v-model="boardInfo.boardId"
                                        :dataSource="boardCodes"
                                        :fields="commonCodeFields"
                                        :allowFiltering="false"
                                        :enabled="false"
                                        cssClass="body-data-dropdown"
                                        @change="changeSelected(boardInfo.boardId)"
                                      ></ejs-dropdownlist>
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title required">제목</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        ref="title"
                                        :maxlength="500"
                                        v-model="boardInfo.title"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">부제목</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        ref="subtitle"
                                        :maxlength="500"
                                        v-model="boardInfo.subtitle"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title required">내용</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <textarea
                                        id="editor"
                                        ref="contents"
                                        v-model="boardInfo.contents"
                                        style="display: none;"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li
                              v-if="boardInfo.boardId === 'event'"
                              class="field reservationDateTime three"
                            >
                              <!-- 필수 : required -->
                              <div
                                class="title"
                                :class="boardInfo.boardId !== 'news' && 'required'"
                              >이벤트 시작</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group date">
                                    <div class="form">
                                      <input-date
                                        ref="eventStart"
                                        type="body-data"
                                        :disabled="boardInfo.boardId === 'news'"
                                        :format="DATE_FORMAT_YYYY_MM_DD"
                                        v-model="boardInfo.startDate"
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group time">
                                    <div class="form">
                                      <input-time
                                        ref="startTime"
                                        :disabled="boardInfo.boardId === 'news'"
                                        :format="'HH:mm'"
                                        v-model="boardInfo.startTime"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li
                              v-if="boardInfo.boardId === 'event'"
                              class="field reservationDateTime three"
                            >
                              <!-- 필수 : required -->
                              <div
                                class="title"
                                :class="boardInfo.boardId !== 'news' && 'required'"
                              >이벤트 종료</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group date">
                                    <div class="form">
                                      <input-date
                                        ref="eventEnd"
                                        type="body-data"
                                        :disabled="boardInfo.boardId === 'news'"
                                        :format="DATE_FORMAT_YYYY_MM_DD"
                                        v-model="boardInfo.endDate"
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group time">
                                    <div class="form">
                                      <input-time
                                        ref="endTime"
                                        :disabled="boardInfo.boardId === 'news'"
                                        :format="'HH:mm'"
                                        v-model="boardInfo.endTime"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li
                              v-if="boardInfo.boardId === 'event'"
                              class="field three"
                            >
                              <div class="title">오픈여부</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group check">
                                    <ul class="check">
                                      <li>
                                        <label>
                                          <input
                                            type="radio"
                                            value="true"
                                            v-model="boardInfo.openFlag"
                                            :disabled="boardInfo.boardId === 'news'"
                                          >
                                          <i></i>
                                          <div class="label">
                                            공개
                                          </div>
                                        </label>
                                      </li>
                                      <li>
                                        <label>
                                          <input
                                            type="radio"
                                            value="false"
                                            v-model="boardInfo.openFlag"
                                            :disabled="boardInfo.boardId === 'news'"
                                          >
                                          <i></i>
                                          <div class="label">
                                            비공개
                                          </div>
                                        </label>
                                      </li>
                                    </ul>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field three">
                              <div class="title">메인 노출</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group check">
                                    <ul class="check">
                                      <li>
                                        <label>
                                          <input
                                            type="radio"
                                            value="true"
                                            v-model="boardInfo.mainFlag"
                                          >
                                          <i></i>
                                          <div class="label">
                                            출력
                                          </div>
                                        </label>
                                      </li>
                                      <li>
                                        <label>
                                          <input
                                            type="radio"
                                            value="false"
                                            v-model="boardInfo.mainFlag"
                                          >
                                          <i></i>
                                          <div class="label">
                                            미출력
                                          </div>
                                        </label>
                                      </li>
                                    </ul>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field three">
                              <div class="title">공개여부</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group check">
                                    <ul class="check">
                                      <li>
                                        <label>
                                          <input
                                            type="radio"
                                            value="false"
                                            v-model="boardInfo.privateFlag"
                                            :disabled="boardInfo.boardId === 'event'"
                                          >
                                          <i></i>
                                          <div class="label">
                                            공개
                                          </div>
                                        </label>
                                      </li>
                                      <li>
                                        <label>
                                          <input
                                            type="radio"
                                            value="true"
                                            v-model="boardInfo.privateFlag"
                                            :disabled="boardInfo.boardId === 'event'"
                                          >
                                          <i></i>
                                          <div class="label">
                                            비공개
                                          </div>
                                        </label>
                                      </li>
                                    </ul>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field three">
                              <div class="title required">정렬</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-number
                                        ref="groupOrder"
                                        v-model="boardInfo.groupOrder"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li
                              v-if="boardInfo.boardId === 'news'"
                              class="field three"
                            >
                              <div class="title">썸네일 이미지</div>
                              <div class="content" style="border: none;">
                                <ul class="row" style="height: 100%;">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input
                                        id="file"
                                        type="file"
                                        accept="image/*"
                                        @change="onNewsThumbnailFileChanged"
                                      >
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                  <li class="item form-group" style="width: 100%;">
                                    <div class="body-list">
                                      <ul>
                                        <li style="border: none;">
                                          <div class="content" style="width: 100%; border: none;">
                                            <div style="float: left;">
                                              {{ subStrByte(boardInfo.newsThumbnail.name, 22) }}
                                            </div>
                                            <div class="delete" style="float: right;">
                                              <erp-button
                                                  button-div="DELETE"
                                                  v-show="boardInfo.newsThumbnail.name"
                                                  :is-icon-custom="true"
                                                  @click.native="onNewsThumbnailFileDeleted"
                                              >
                                                삭제
                                              </erp-button>
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li
                              v-if="boardInfo.boardId === 'news'"
                              class="field"
                              style="width: calc(100% - (100% / 3 + 1px));"
                            >
                              <div class="title">첨부파일</div>
                              <div class="content">
                                <ul class="row" style="height: 100%;">
                                  <li class="item form-group" style="width: 198px; height: 100%; padding-top: 18px;">
                                    <div class="form">
                                      <input
                                        ref="attachmentFile"
                                        type="file"
                                        accept=".jpg, .jpeg, .gif, .png, .bmp, .doc, .docx, .hwp, .xls, .xlsx, .zip, .pdf, .ppt, .pptx"
                                        multiple
                                        @change="onAttachmentFileChanged"
                                      >
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                  <li class="item form-group" style="width: calc(100% - 198px); height: 62px; overflow-y: scroll;">
                                    <div class="body-list">
                                      <ul
                                        v-for="(item, idx) in getAttachmentFiles"
                                        :key="idx"
                                        style="padding: 0 1px;"
                                      >
                                        <li style="border: none;">
                                          <div class="content" style="width: 100%; border: none;">
                                            <div style="float: left;">
                                              {{ subStrByte(item.name, 40) }}
                                            </div>
                                            <div class="delete" style="float: right;">
                                              <erp-button
                                                  button-div="DELETE"
                                                  :is-icon-custom="true"
                                                  @click.native="onAttachmentFileDeleted($event, item, idx)"
                                              >
                                                삭제
                                              </erp-button>
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
                <section
                  v-if="boardInfo.boardId === 'event'"
                  class="article-section section-0102"
                >
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">썸네일 등록</div>
                    </div>
                    <div class="header-right">
                      <ul class="header-button">
                        <li>
                          <input
                            v-show="false"
                            ref="gridFileUpload"
                            id="gridFileUpload"
                            type="file"
                            accept=".jpg, .jpeg, .gif, .png, .bmp"
                            @change="onGridFileUploadChanged"
                          />
                        </li>
                        <li>
                          <erp-button
                              button-div="SAVE"
                              :is-icon-custom="true"
                              @click.native="onGridAddButtonClicked"
                          >
                            추가
                          </erp-button>
                        </li>
                        <li>
                          <erp-button
                              button-div="DELETE"
                              @click.native="onGridDeleteButtonClicked"
                          >
                            삭제
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body" style="height: 120px;">
                    <ejs-grid-wrapper
                      ref="grid"
                      v-bind="gridOptions"
                      :dataSource="boardInfo.eventThumbnails"
                      @headerCellInfo="onGridHeaderCellInfo"
                      @queryCellInfo="onGridQueryCellInfo"
                      @recordClick="onGridRecordClick"
                    />
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button custom-btn">
            <li class="delete">
              <erp-button
                  button-div="DELETE"
                  :is-icon-custom="true"
                  :disabled="newSaveCheck"
                  :is-shortcut-button="true"
                  @click.native="onDeleteButtonClicked"
              >
                삭제
              </erp-button>
            </li>
          </ul>
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-key-color="true"
                  :is-shortcut-button="true"
                  @click.native="onSaveButtonClicked"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onCloseButtonClicked"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
body .window .windowContent .body-article .body-article {height: auto;}
body .window .windowContent .article-section.section-0102 {height: 165px;}
body .window .windowContent .article-section.section-0101 .section-body,
body .window .windowContent .article-section.section-0102 .section-body {overflow: hidden; border: none;}
body .window .windowContent .article-section .body-data .field {width: calc(100% + 1px);}
body .window .windowContent .article-section .body-data .field.three {width: calc((100% / 3) + 1px);}

.custom-btn {
  float: left !important;
}
input[type=file] {
  font-size: 12px;
  width: 100%;
}
input[type=file]::file-selector-button {
  width: 80px;
  height: 25px;
  background: black;
  color: white;
  border-radius: 3px;
  cursor: pointer;
}
</style>

<script>
import Vue from "vue";
import { v4 as createUUID } from "uuid";

import CustomCkeditor from "@/utils/ckeditor/CustomCkeditor";

import {
  DATE_FORMAT_YYYY_MM_DD, getFormattedDate,
} from "@/utils/date";
import InputTime from "@/components/common/datetime/InputTime";
import InputDate from "@/components/common/datetime/InputDate";
import InputText from "@/components/common/text/InputText";
import InputNumber from "@/components/common/InputNumber";
import {validateFormRefs} from "@/utils/formUtil";
import {
  commonCodesGetCommonCode,
} from "@/utils/commonCodes";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import {Edit, ExcelExport, Filter, ForeignKey, Resize} from "@syncfusion/ej2-vue-grids";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import {MultiSelect} from "@syncfusion/ej2-vue-dropdowns";
import {getCurrentBizCode} from "@/utils/pageKeyUtil";
import {getAccessToken} from "@/utils/session/SessionUtil";
import {encodeBase64} from "@/utils/ImageUtil";
import {subStrByte} from "@/utils/string";
import {maxBy as _maxBy, orderBy as _orderBy} from "lodash";
import moment from "moment";
import ErpButton from "@/components/button/ErpButton";

let elem;
let multiSelectObj;

let codeList = commonCodesGetCommonCode("BANNER_LOCATION");

function setCode(args) {
  codeList = codeList.filter((data) => {
    return !args.includes(data.comCode);
  });
}

function initCode(args) {
  const originCodeList = commonCodesGetCommonCode("BANNER_LOCATION");
  const tmpList = args
    .map((data) => {
      return data.bannerLocations;
    })
    .flat();
  codeList = originCodeList.filter((data) => {
    return !tmpList.includes(data.comCode);
  });
}

function addSetCode(args) {
  const originCodeList = commonCodesGetCommonCode("BANNER_LOCATION");
  const tmpList = originCodeList.filter((data) => {
    return args.includes(data.comCode);
  });
  tmpList.forEach((data) => {
    if (codeList.findIndex((code) => code.comCode === data.comCode) === -1) {
      codeList.push(data);
    }
  });
}

export default {
  name: "PostRegistrationPopup",
  components: {
    EjsGridWrapper,
    InputText,
    InputNumber,
    InputDate,
    InputTime,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin, routeViewMixin],
  created() {},
  mounted() {},
  data() {
    return {
      DATE_FORMAT_YYYY_MM_DD,
      boardCodes: [{ comCode: 'news', comName: '공지사항'},{ comCode: 'event', comName: '이벤트',}],
      bannerLocationSource: commonCodesGetCommonCode("BANNER_LOCATION"),
      commonCodeFields: { text: "comName", value: "comCode" },
      editor: null,
      newPostId: null,
      boardInfo: {
        postId: null,
        boardId: null,
        title: null,
        subtitle: null,
        groupOrder: null,
        contents: "",
        mainFlag: false,
        openFlag: null,
        privateFlag: true,
        startDate: null,
        endDate: null,
        startTime: "00:00",
        endTime: "00:00",
        newsThumbnail: {
          name: null,
        },
        eventThumbnails: [],
        attachmentFiles: [],
      },
      newsRules: {
        div: {
          required: true,
        },
        title: {
          required: true,
        },
        contents: {
          required: true,
        },
        groupOrder: {
          required: true,
        },
      },
      eventRules: {
        div: {
          required: true,
        },
        title: {
          required: true,
        },
        contents: {
          required: true,
        },
        eventStart: {
          required: true,
        },
        startTime: {
          required: true,
        },
        eventEnd: {
          required: true,
        },
        endTime: {
          required: true,
        },
        groupOrder: {
          required: true,
        },
      }
    };
  },
  computed: {
    getAttachmentFiles() {
      return this.boardInfo.attachmentFiles.filter(item => !item.isDelete);
    },
    editorConfig() {
      const bizCode = getCurrentBizCode();
      const accessToken = getAccessToken();

      return {
        simpleUpload: {
          // uploadUrl: `${process.env.VUE_APP_HOST_API_BASE_V2}/boards/${this.boardInfo.boardId}/posts/${this.newPostId}/image`,
          // withCredentials: true,
          headers: {
            "x-transaction-id": createUUID(),
            Authorization: `${bizCode} ${accessToken}`,
            boardId: this.boardInfo.boardId,
            postId: this.boardInfo.postId || this.newPostId,
          },
        },
        toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'uploadImage'],
      };
    },
    newSaveCheck() {
      return !this.boardInfo.postId;
    },
    gridOptions() {
      return {
        provides: [Edit, Filter, ForeignKey, Resize, ExcelExport],
        allowFiltering: false,
        selectionSettings: {
          type: "Single",
          mode: "Both",
          enableToggle: false,
        },
        editSettings: {
          allowEditing: true,
          allowAdding: true,
          allowDeleting: true,
          mode: "Batch",
          showConfirmDialog: false,
          newRowPosition: "Bottom",
        },
        columns: [
          {
            field: "imageId",
            type: "number",
            minWidth: 16,
            width: 50,
            isPrimaryKey: true,
            visible: false,
          },
          {
            field: "file",
            headerText: "",
            type: "string",
            minWidth: 16,
            width: 50,
            allowEditing: false,
          },
          {
            field: "fileData",
            type: "blob",
            width: "150",
            allowEditing: false,
            visible: false,
          },
          {
            field: "filename",
            headerText: "파일명",
            type: "string",
            minWidth: 16,
            width: 150,
            allowEditing: false,
          },
          {
            field: "bannerLocations",
            headerText: "배너위치",
            type: "string",
            minWidth: 16,
            width: 210,
            edit: {
              create() {
                elem = document.createElement("input");
                return elem;
              },
              read() {
                setCode(multiSelectObj.value);
                return multiSelectObj.value;
              },
              destroy() {
                multiSelectObj.destroy();
              },
              write(args) {
                addSetCode(args.rowData.bannerLocations);
                multiSelectObj = new MultiSelect({
                  dataSource: codeList,
                  fields: { text: "comName", value: "comCode" },
                  value: args.rowData.bannerLocations,
                });
                multiSelectObj.appendTo(elem);
              },
            },
            template: () => {
              return {
                template: Vue.component("spanMultiselectString", {
                  template: `<span>{{ text }}</span>`,
                  data() {
                    return {
                      data: {},
                    };
                  },
                  computed: {
                    originCodes() {
                      return commonCodesGetCommonCode("BANNER_LOCATION");
                    },
                    text() {
                      if (this.data.bannerLocations) {
                        const tmpList = this.originCodes.filter((code) => {
                          return this.data.bannerLocations.includes(code.comCode);
                        });
                        return tmpList.map((d) => d.comName).join(", ");
                      } else {
                        return null;
                      }
                    },
                  },
                }),
              };
            },
          },
          {
            field: "insertId",
            headerText: "등록자",
            type: "string",
            minWidth: 16,
            width: 90,
            allowEditing: false,
          },
          {
            field: "insertDt",
            headerText: "등록일시",
            type: "string",
            minWidth: 16,
            width: 130,
            allowEditing: false,
          },
          {
            field: "updateId",
            headerText: "수정자",
            type: "string",
            minWidth: 16,
            width: 90,
            allowEditing: false,
          },
          {
            field: "updateDt",
            headerText: "수정일시",
            type: "string",
            minWidth: 16,
            width: 130,
            allowEditing: false,
          },
        ],
        validationModification: false,
        validationRules: {
          filename: {
            required: true,
            maxLength: 100,
          },
          bannerLocations: {
            required: true,
          },
        },
      };
    },
  },
  methods: {
    subStrByte,
    validateFormRefs,
    async showPopup(args) {
      this.boardInfo.boardId = args.boardId;
      this.boardInfo.postId = args.postId;

      if (!args.postId) {
        await this.createPost();
      } else {
        await this.searchPosts();
      }

      new CustomCkeditor("#editor", this.onCustomCkeditorCreated).create(this.editorConfig);
    },
    async searchPosts() {
      const data = await GolfErpAPI.fetchSearchPostsById(this.boardInfo.boardId, this.boardInfo.postId);

      this.boardInfo = ({
        ...data,
        postId: data.id,
        privateFlag: data.boardId !== "news" ? null : data.privateFlag,
        startDate: data.twPostEvents.length > 0 ?
          getFormattedDate(data.twPostEvents[0].startDt) :
          null,
        startTime: data.twPostEvents.length > 0 ?
          getFormattedDate(data.twPostEvents[0].startDt, "HH:mm") :
          null,
        endDate: data.twPostEvents.length > 0 ?
          getFormattedDate(data.twPostEvents[0].endDt) :
          null,
        endTime: data.twPostEvents.length > 0 ?
          getFormattedDate(data.twPostEvents[0].endDt, "HH:mm") :
          null,
        openFlag: data.twPostEvents.length > 0 ?
          data.twPostEvents[0].openFlag :
          data.boardId !== "event" ? null : false,
        attachmentFiles: data.twPostAttachments.length > 0 ?
          data.twPostAttachments.map((item, idx) => ({
            ...item,
            name: item?.twAttachment?.filename || null,
            idx: idx,
            isDelete: false,
          })) :
          [],
        newsThumbnail: data.twPostImages.filter(item => item.imageId === data.thumbnailImageId).length > 0 ?
          {
            name: data.twPostImages[0].twImage.filename,
          } :
          {
            name: null,
          },
        eventThumbnails: data.twPostEvents.length > 0 ?
          _orderBy(data.twPostEvents.map(twPostEvent => ({
            ...twPostEvent,
            imageId: twPostEvent?.thumbnailImageId,
            filename: twPostEvent?.twImage?.filename,
            bannerLocations: twPostEvent?.twImage?.twMainBanners ?
              twPostEvent?.twImage?.twMainBanners?.map(twMainBanner => twMainBanner.bannerLocation) :
              [],
            insertId: twPostEvent?.twImage?.insertId,
            insertDt: twPostEvent?.twImage?.insertDt,
            updateId: twPostEvent?.twImage?.updateId,
            updateDt: twPostEvent?.twImage?.updateDt,
          })), ["eventId"]) :
          [],
      });

      initCode(this.boardInfo.eventThumbnails);
    },
    onCustomCkeditorCreated(editor) {
      this.editor = editor;
      this.editor.setData(this.boardInfo.contents);
    },
    async createPost() {
      this.newPostId = await GolfErpAPI.createPost(this.boardInfo.boardId);
      initCode(this.boardInfo.eventThumbnails);
    },
    onGridAddButtonClicked() {
      this.$refs.grid.addRecord({
        filename: null,
        bannerLocations: [],
      });
    },
    onGridDeleteButtonClicked() {
      this.$refs.grid.deleteRecord();
    },
    onGridHeaderCellInfo(args) {
      const {
        cell: {
          column: {
            field
          },
        },
        node: {
          classList
        },
      } = args;

      if (
        [
          "filename",
          "bannerLocations"
        ].includes(field)
      ) {
        // 헤더에 필수영역 표시 클래스 추가
        classList.add(this.$t("className.grid.requiredInputHeader"));
      } else if (field === "file") {
        classList.add(this.$t("className.grid.searchIconArea"));
      }
    },
    onGridQueryCellInfo(args) {
      const {
        column: {
          field,
        },
        cell,
      } = args;

      if (
        [
          "filename",
          "bannerLocations"
        ].includes(field)
      ) {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      } else if (field === "file") {
        cell.classList.add(this.$t("className.grid.searchIconArea"));
      }
    },
    onGridRecordClick(args) {
      const {
        column: {
          field,
        }
      } = args;

      if (field === "file") {
        this.$refs.gridFileUpload.click();
      }
    },
    onGridFileUploadChanged(args) {
      const file = args.target.files[0];

      const rowIndex = this.$refs.grid.getSelectedRowIndexes();

      if (!(rowIndex.length > 0)) {
        this.errorToast("이미지 가져오기에 실패하였습니다");
        return;
      }

      this.$refs.grid.updateCell(rowIndex, "fileData", file);
      this.$refs.grid.updateCell(rowIndex, "filename", file.name);

      this.$refs.gridFileUpload.value = "";
    },
    async onSaveButtonClicked() {
      await (() => {
        this.boardInfo.contents = this.editor.getData();
      })();
      if (this.boardInfo.boardId === "news") {
        if (!this.validateFormRefs(this.newsRules)) {
          await this.customValidate(this.newsRules);
          return;
        }
      } else if (this.boardInfo.boardId === "event") {
        if (!this.validateFormRefs(this.eventRules)) {
          await this.customValidate(this.eventRules);
          return;
        }

        if (!this.$refs.grid.validate()) {
          return;
        }

        const viewRecords = this.$refs.grid.getBatchCurrentViewRecords();

        if (!(viewRecords.filter(item => item.bannerLocations.includes("MAIN")).length > 0)) {
          this.errorToast("이벤트 썸네일은 배너위치가 메인인 데이터가 있어야 합니다");
          return;
        }
      } else {
        this.errorToast("게시물 구분이 잘못되었습니다");
        return;
      }

      const saveData = ({
        ...this.boardInfo,
        id: this.boardInfo.postId || this.newPostId,
        attachmentFiles: [],
        newsThumbnail: {},
        eventThumbnails: [],
      });

      if (this.boardInfo.boardId === "news") {
        saveData.newsThumbnail = this.boardInfo.newsThumbnail;
        saveData.attachmentFiles = this.boardInfo.attachmentFiles;
      } else if (this.boardInfo.boardId === "event") {
        saveData.eventStartDt = moment(this.boardInfo.startDate + " " + this.boardInfo.startTime).format("YYYY-MM-DD HH:mm:ss");
        saveData.eventEndDt = moment(this.boardInfo.endDate + " " + this.boardInfo.endTime).format("YYYY-MM-DD HH:mm:ss");
        const {
          addedRecords,
          changedRecords,
          deletedRecords,
        } = this.$refs.grid.getBatchChanges();

        if (addedRecords.length > 0) {
          for (const item of addedRecords) {
            const path = await encodeBase64(item.fileData);

            saveData.eventThumbnails.push({
              id: null,
              name: item.fileData.name,
              path: path,
              isDelete: false,
              mainBanners: item.bannerLocations.map(banner => ({
                bannerLocation: banner,
                order: item._rid,
              }))
            });
          }
        }
        if (changedRecords.length > 0) {
          for (const item of changedRecords) {
            let path = null;
            let name = null;

            if (item.fileData) {
              name = item.fileData.name;
              path = await encodeBase64(item.fileData);
            }

            saveData.eventThumbnails.push({
              id: item?.twImage?.id,
              eventId: item.eventId,
              name: name,
              path: path,
              isDelete: false,
              mainBanners: item.bannerLocations.map(banner => ({
                bannerLocation: banner,
                order: item._rid,
              }))
            });
          }
        }
        if (deletedRecords.length > 0) {
          for (const item of deletedRecords) {
            saveData.eventThumbnails.push({
              id: item?.twImage?.id,
              eventId: item.eventId,
              isDelete: true,
            });
          }
        }
      }

      await GolfErpAPI.savePost(this.boardInfo.boardId, saveData);

      if (!this.boardInfo.postId) {
        this.boardInfo.postId = this.newPostId;
      }

      this.searchPosts();

      this.$emit("refresh");
    },
    async onDeleteButtonClicked() {
      if (!(await this.confirm("게시물을 삭제하시겠습니까?"))) {
        return;
      }

      await GolfErpAPI.deletePost(this.boardInfo.boardId, this.boardInfo.postId);
      this.$emit("refresh");
      this.$emit("popupClosed");
    },
    customValidate(list) {
      let count = 0;
      Object.keys(list).map((data,idx)=>{
        if(this.$refs[data].value) {
          count++;
        }
        if(data==="contents" && count === idx && !this.$refs[data].value){
          document.getElementsByClassName("ck-content")[0].focus();
        }
        return;
      });
    },
    changeSelected(check) {
      if (check === "news") {
        this.boardInfo.openFlag = null;
        this.boardInfo.privateFlag = true;
        this.boardInfo.eventStart = null;
        this.boardInfo.startTime = null;
        this.boardInfo.eventEnd = null;
        this.boardInfo.endTime = null;
      } else if (check === "event") {
        this.boardInfo.privateFlag = null;
        this.boardInfo.openFlag = true;
      }
    },
    onCloseButtonClicked() {
      this.$refs.postRegistrationPopup.hide();
    },
    onPopupClosed() {
      this.$emit("popupClosed");
    },
    async onNewsThumbnailFileChanged(args) {
      const {
        target: {
          files,
        },
      } = args;

      if (files.length > 0) {
        const file = files[0];
        const path = await encodeBase64(file);
        this.boardInfo.newsThumbnail = {
          path: path,
          name: file.name,
        };
      }
    },
    async onAttachmentFileChanged(args) {
      const {
        target: {
          files,
        },
      } = args;

      for (let i = 0; i < files.length; i++) {
        const file = files.item(i);
        const path = await encodeBase64(file);
        this.boardInfo.attachmentFiles.push({
          path: path,
          name: file.name,
          idx: (_maxBy(this.boardInfo.attachmentFiles.map(item => item.idx)) || 0) + 1,
          isDelete: false,
        });
      }
    },
    onNewsThumbnailFileDeleted() {
      this.boardInfo.newsThumbnail = {
        name: null,
      };
    },
    onAttachmentFileDeleted(event, file) {
      if (file.id) {
        const idx = this.boardInfo.attachmentFiles.findIndex(item => item.idx === file.idx);
        if (idx >= 0) {
          this.boardInfo.attachmentFiles[idx].isDelete = true;
        }
      } else {
        const idx = this.boardInfo.attachmentFiles.findIndex(item => item.idx === file.idx);
        if (idx >= 0) {
          this.boardInfo.attachmentFiles.splice(idx, 1);
        }
      }
    }
  }
};
</script>
