import { render, staticRenderFns } from "./PostRegistration.vue?vue&type=template&id=2e795e7b&scoped=true&"
import script from "./PostRegistration.vue?vue&type=script&lang=js&"
export * from "./PostRegistration.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e795e7b",
  null
  
)

export default component.exports