<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">구분</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                  v-model="searchOptions.boardId"
                  :dataSource="searchOptions.boardIds"
                  :fields="commonCodeFields"
                  cssClass="lookup-condition-dropdown"
                  @change="onBoardIdChanged"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              :ignore="isPopupOpened"
              @click.native="onViewButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">게시물 목록</div>
              <div class="header-caption">[{{ boardPostsCount }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="add">
                  <erp-button
                      button-div="SAVE"
                      :ignore="isPopupOpened"
                      :is-icon-custom="true"
                      :is-custom-shortcut-button="true"
                      shortcut-key="postRegistration.shortcuts.addPost"
                      :shortcut="{key: 'F3'}"
                      @click="onAddButtonClicked"
                  >
                    추가
                  </erp-button>
                </li>
                <li class="delete">
                  <erp-button
                      button-div="DELETE"
                      :is-shortcut-button="true"
                      :ignore="isPopupOpened"
                      @click="onDeleteButtonClicked"
                  >
                    삭제
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body" style="border: none;">
            <div class="body-grid">
              <ejs-grid-wrapper
                ref="grid"
                v-bind="gridOptions"
                :dataSource="boardPosts"
                @headerCellInfo="onGridHeaderCellInfo"
                @queryCellInfo="onGridQueryCellInfo"
                @recordClick="onGridRecordClick"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
    <post-registration-popup
      ref="postRegistrationPopup"
      v-if="isPostRegistrationPopup"
      @popupClosed="popupClosed"
      @refresh="onViewButtonClicked"
    />
  </div>
</template>

<script>
import PostRegistrationPopup from "@/views/code-management/popup/PostRegistrationPopup";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {Edit, ExcelExport, Filter, ForeignKey, Resize} from "@syncfusion/ej2-vue-grids";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "PostRegistration",
  components:{
    ejsGridWrapper,
    PostRegistrationPopup,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin, routeViewMixin],
  data(){
    return{
      isPostRegistrationPopup: false,
      boardPosts: [],
      boardPostsCount: 0,
      commonCodeFields: {text: 'comName', value: 'comCode'},
      searchOptions: {
        boardId: "news",
        boardIds: [
          {
            comCode: "news",
            comName: "공지사항",
          },
          {
            comCode: "event",
            comName: "이벤트"
          },
        ],
      }
    };
  },
  computed:{
    isPopupOpened() {
      return (
          this.isPostRegistrationPopup
      );
    },
    gridOptions() {
      return {
        provides: [Edit, Filter, ForeignKey, Resize, ExcelExport],
        selectionSettings: {
          type: "Single",
          mode: "Both",
          enableToggle: false,
        },
        editSettings: {
          allowEditing: false,
          mode: "Batch",
        },
        allowResizing: true,
        columns: [
          {
            headerText: "제목",
            field: "title",
            type: "string",
            minWidth: 16,
            width: 240,
          },
          {
            headerText: "부제목",
            field: "subtitle",
            type: "string",
            minWidth: 16,
            width: 330,
          },
          {
            headerText: "썸네일 이미지",
            field: "isThumbnailImage",
            type: "boolean",
            editType: "booleanedit",
            displayAsCheckBox: true,
            textAlign: "Center",
            minWidth: 16,
            width: 120,
          },
          {
            headerText: "첨부파일",
            field: "attachmentCount",
            type: "number",
            format: "N",
            textAlign: "right",
            minWidth: 16,
            width: 90,
          },
          {
            headerText: "이벤트 시작 일시",
            field: "eventStartDt",
            type: "string",
            textAlign: "center",
            minWidth: 16,
            width: 130,
          },
          {
            headerText: "이벤트 종료 일시",
            field: "eventEndDt",
            type: "string",
            textAlign: "center",
            minWidth: 16,
            width: 130,
          },
          {
            headerText: "정렬",
            field: "groupOrder",
            type: "number",
            format: "N",
            textAlign: "right",
            minWidth: 16,
            width: 70,
          },
          {
            headerText: "메인노출",
            field: "mainFlag",
            type: "boolean",
            editType: "booleanedit",
            displayAsCheckBox: true,
            textAlign: "Center",
            minWidth: 16,
            width: 90,
          },
          {
            headerText: "공개여부",
            field: "privateFlag",
            textAlign: "Center",
            minWidth: 16,
            width: 90,
            editType: "booleanedit",
            type: "boolean",
            displayAsCheckBox: true,
          },
          {
            headerText: "오픈여부",
            field: "openFlag",
            textAlign: "Center",
            minWidth: 16,
            width: 90,
            editType: "booleanedit",
            type: "boolean",
            displayAsCheckBox: true,
          },
          {
            headerText: "작성자",
            field: "writerName",
            type: "string",
            minWidth: 16,
            width: 90,
          },
          {
            headerText: "작성일시",
            field: "insertDt",
            type: "string",
            minWidth: 16,
            width: 130,
          },
          {
            headerText: "수정자",
            field: "updateId",
            type: "string",
            minWidth: 16,
            width: 90,
          },
          {
            headerText: "수정일시",
            field: "updateDt",
            type: "string",
            minWidth: 16,
            width: 130,
          },
        ]
      };
    }
  },
  methods: {
    async onViewButtonClicked() {
      const response = await GolfErpAPI.fetchBoardWithPosts({
        boardId: this.searchOptions.boardId,
        offset: 0,
        limit: 50,
      });

      this.boardPosts = response?.posts.map(item => ({
        ...item,
        privateFlag: !item.privateFlag,
        isThumbnailImage: !!item.thumbnailImageId,
        isAttachment: false,
      })) || [];
      this.boardPostsCount = response?.total || 0;
    },
    onBoardIdChanged() {
      this.onViewButtonClicked();
    },
    onAddButtonClicked() {
      this.postRegistrationPopupOpen();
    },
    postRegistrationPopupOpen(boardId, postId) {
      this.isPostRegistrationPopup = true;

      this.$nextTick(() => {
        this.$refs.postRegistrationPopup.showPopup({
          boardId: boardId || this.searchOptions.boardId,
          postId: postId,
        });
      });
    },
    async onDeleteButtonClicked() {
      if (!(await this.confirm("선택하신 게시물을 삭제하시겠습니까?"))) {
        return;
      }

      const selectedRecords = this.$refs.grid.getSelectedRecords();

      if (!(selectedRecords.length > 0)) {
        this.errorToast("선택된 게시물이 없습니다");
        return;
      }

      await GolfErpAPI.deletePost(selectedRecords[0].boardId, selectedRecords[0].id);

      this.onViewButtonClicked();
    },
    popupClosed() {
      this.isPostRegistrationPopup = false;
    },
    onGridHeaderCellInfo(args) {
      const {
        cell: {
          column: {
            field
          },
        },
        node: {
          classList
        },
      } = args;

      if (field === "title") {
        classList.add(this.$t("className.grid.clickArea"));
      }
    },
    onGridQueryCellInfo(args) {
      const {
        column: {
          field,
        },
        cell,
      } = args;

      if (field === "title") {
        cell.classList.add(this.$t("className.grid.clickArea"));
      }
    },
    onGridRecordClick(args) {
      const {
        column: {
          field,
        },
        rowData,
      } = args;

      if (field === "title") {
        this.postRegistrationPopupOpen(rowData.boardId, rowData.id);
      }
    },
  }
};
</script>

<style scoped>

</style>